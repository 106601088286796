import React from "react"
import { StaticQuery, graphql } from "gatsby"

const ALL_CATEGORIES_QUERY = graphql`
  query GetLookingFor {
    wpgraphql {
          lookingForLocations(where: {termTaxonomId: "157"})  {
            nodes {
              name
              lookingFor {
                nodes {
                  title
                  content
                  whatLookingForOptions {
                    fieldGroupName
                    notWhatYourLookingForButtonLeftLabel
                    notWhatYourLookingForButtonLeftLink
                    notWhatYourLookingForButtonMiddleLabel
                    notWhatYourLookingForButtonMiddleLink
                    notWhatYourLookingForButtonRightLabel
                    notWhatYourLookingForButtonRightLink
                    notWhatYourLookingForText
                    notWhatYourLookingForTitle
                  }
                }
              }
            }
          }
    }
  }
`


const LookingFor = () => (
  <StaticQuery
    query={ALL_CATEGORIES_QUERY}
    render={data => {
      const { lookingForLocations } = data.wpgraphql
      return (
        <section className="content navy-background">
        <div className="sixty-spacer"></div>

                <div className="content-block cta-not-what-wrapper">

                   <div className="one-flex-container">
                               <div className="twothirds-flex-container sequenFadeIn sequen-visible">
                                        <div className="cta-help-title-holder fadeIn visible" dangerouslySetInnerHTML={{ __html: lookingForLocations.nodes[0].lookingFor.nodes[0].title }}/>
                      </div>
                   </div>

                   <div className="one-flex-container">
                      <div className="half-flex-container sequenFadeIn sequen-visible">
                            <div className="cta-help-text-holder fadeIn visible" dangerouslySetInnerHTML={{ __html: lookingForLocations.nodes[0].lookingFor.nodes[0].content }}/>
                      </div>
                               <div className="half-flex-container sequenFadeIn sequen-visible">
                         <div className="cta-not-what-content-container clearfix fadeIn visible">
                            <div className="cta-not-what-label-holder">
                               {lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForTitle}
                            </div>

                            <div className="cta-not-what-link-holder">
                               <nav>
                                  <ul>
                                  {lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonLeftLabel ? (
                                    <li>
                                     <a href={lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonLeftLink}>
                                        {lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonLeftLabel}
                                     </a>
                                  </li>
                                   ) : null}

                                   {lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonMiddleLabel ? (
                                     <li>
                                      <a href={lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonMiddleLink}>
                                         {lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonMiddleLabel}
                                      </a>
                                   </li>
                                    ) : null}

                                   {lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonRightLabel ? (
                                     <li>
                                      <a href={lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonRightLink}>
                                         {lookingForLocations.nodes[0].lookingFor.nodes[0].whatLookingForOptions.notWhatYourLookingForButtonRightLabel}
                                      </a>
                                   </li>
                                    ) : null}

                                                          </ul>
                               </nav>
                            </div>
                         </div>
                      </div>
                   </div>

                </div>
                <div className="sixty-spacer"></div>
    </section>
      )
    }}
  />
)

export default LookingFor
