
// This is a temporary generated file. Changes to this file will be overwritten eventually!
import React from "react"
import Header from "../src/components/Header"
import Layout from "../src/components/Layout"
import SEO from "../src/components/SEO"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { decode } from 'he'
import CategoriesWidget from "../src/components/categoriesWidget"
import LookingFor from "../src/components/LookingFor"

const Page = ({ pageContext }) => {
  const {
    page: { title, content,slug,seo },
    breadcrumb: { crumbs },
} = pageContext

const isPartiallyActive = ({ isPartiallyCurrent, isCurrent }) => {
  return isPartiallyCurrent && isCurrent
    ? { className: 'breadcrumb__link breadcrumb__link__active' }
    : {}
}

function facebookShare(slug){
  return "https://www.facebook.com/sharer/sharer.php?u=https://www.htb.co.uk/blog/" + slug;
}
function linkedInShare(slug){
  return "https://www.linkedin.com/sharing/share-offsite/?url=https://www.htb.co.uk/blog/" + slug;
}
function twitterShare(slug){
  return "http://twitter.com/share?text=" + title + "&amp;url=https://www.htb.co.uk/blog/" + slug;
}


const newCrumbs = crumbs.map(item => {

  const container = {};
  if(item.pathname=='/case-study/'){
    container.pathname="/";
    container.crumbLabel = decode(item.crumbLabel.replace(/-/g, ' '));
  }
  else{
    container.crumbLabel = decode(item.crumbLabel.replace(/-/g, ' '));
    container.pathname = item.pathname;
  }



  return container;
})


  return (
    <>
    <Header siteTitle={title} />
    <SEO title={seo.title} description={seo.metaDesc}/>
    <main className="wrapper">
    <section className="content no-image-breadcrumb">

    <div className="breadcrumbs">
    <Breadcrumb
            crumbs={newCrumbs}
            crumbSeparator=" / "
            crumbLabel={decode(title)}
            getProps={isPartiallyActive}
          />
    </div>
    </section>
    <Layout>


    <section className="content" id="development-finance-colour">
      <div className="content-block clearfix">
          <div className="eighty-spacer"></div>
              <div className="news-single-centered-block case-study-single-title-holders">
              <h1> {title} </h1>
              </div>
          <div className="thirty-spacer"></div>
      </div>
    </section>

    <section className="content">
        <div className="content-block clearfix">
            <div className="news-single-content case-study-single">
              <div dangerouslySetInnerHTML={{ __html: content }}/>
              <div className="social-share">
              <span>
              Share
              </span>
              <ul>
              <a href={facebookShare(slug)} target="_blank" rel="noopener noreferrer"><li className="share-facebook"></li></a>
              <a href={linkedInShare(slug)} target="_blank" rel="noopener noreferrer"><li className="share-linkedin"></li></a>
              <a href={twitterShare(slug)} target="_blank" rel="noopener noreferrer"><li className="share-twitter"></li></a>
              </ul>
              </div>
            </div>
        </div>
        <div className="eighty-spacer"></div>
    </section>

    <LookingFor />
    <CategoriesWidget />
    </Layout>

    </main>
    </>

  )
}

export default Page
  